.select-field{
    margin-top: 16px;
}
.select-field .MuiFormControl-root{
    margin: 0 !important;
    width: 100% !important;
}
.select-field .MuiSelect-select:focus-visible{
    outline: none !important;
}
.select-field .MuiSelect-nativeInput:focus-visible{
    outline: none !important;
}
.select-field .MuiInputBase-root:hover{
    border: none !important;
    outline: none !important;
}
.select-field .MuiOutlinedInput-notchedOutline{
    outline: none !important;
    border: 1px solid #B9B9B9 !important;
}