.content_wrapper {
  margin-top: 73px;
  will-change: padding-left;
  transition: padding-left 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  height: calc(100vh - 73px);
}

.content_expand {
  padding-left: 146px;
}
.content_collapse {
  padding-left: 50px;
}

.outer_container {
  height: 100%;
  /* min-height: calc(100vh - 73px); */
  /* max-height: calc(100vh - 73px); */
  overflow: auto;
}
