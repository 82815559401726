.stepper-box{
	padding: 15px 50px;
	border: 1px solid #E4E4E4;
	display: flex;
	justify-content: space-between;
	position: relative;
}
.stepper-box .stepper-div{
	width: 89px;
	height: 92px;
	text-align: center;
}
.stepper-box .stepper-count{
	background: #333840;
	box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
	border-radius: 50%;
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #FFFFFF;
	width: 48px;
	height: 48px;
	margin: auto;
	position: relative;
	z-index: 999;
}
.stepper-box .stepper-text{
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	color: #333840;
	margin-top: 12px;
}
.stepper-box .stepper-count-inactive{
	background: #FAFAFA;
	box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
	border-radius: 50%;
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #CDCDCD;
	width: 48px;
	height: 48px;
	margin: auto;
	position: relative;
	z-index: 999;
}
.stepper-box .stepper-text-inactive{
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	color: #CDCDCD;
	margin-top: 12px;
}
.stepper-lineactive{
	border: 2px solid #333840;
}
.stepper-lineInactive{
	border: 2px solid #F0F0F0;
}
.stepper-line{
	position: absolute;
	width: 320px;
	top: 31%;
	left: 10%;
}
.stepper-line2{
	position: absolute;
	width: 320px;
	top: 31%;
	left: 50%;
}

@media screen and (max-width: 1000px) {
	.stepper-line{
		position: absolute;
		width: 270px;
		top: 31%;
		left: 12%;
	}
	.stepper-line2{
		position: absolute;
		width: 336px;
		top: 31%;
		left: 50%;
	}
}

@media screen and (max-width: 800px) {
	.stepper-line{
		position: absolute;
		width: 270px;
		top: 31%;
		left: 12%;
	}
	.stepper-line2{
		position: absolute;
		width: 270px;
		top: 31%;
		left: 50%;
	}
}