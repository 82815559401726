/* importing madani fonts */

@font-face {
  font-family: 'Madani-Black';
  src: url('../assets/madaniFonts/MadaniBlack.ttf');
}

@font-face {
  font-family: 'Madani-Bold';
  src: url('../assets/madaniFonts/MadaniBold.ttf');
}

@font-face {
  font-family: 'Madani-Light';
  src: url('../assets/madaniFonts/MadaniLight.ttf');
}

@font-face {
  font-family: 'Madani-Medium';
  src: url('../assets/madaniFonts/MadaniMedium.ttf');
}

@font-face {
  font-family: 'Madani-Regular';
  src: url('../assets/madaniFonts/MadaniRegular.ttf');
}

@font-face {
  font-family: 'Madani-Oblique';
  src: url('../assets/madaniFonts/MadaniOblique.ttf');
}

@font-face {
  font-family: 'Madani-Thin';
  src: url('../assets/madaniFonts/MadaniThin.ttf');
}

@font-face {
  font-family: 'Madani-SemiBold';
  src: url('../assets/madaniFonts/MadaniSemiBold.ttf');
}
