.c-stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.c-stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.c-stepper-item::after {
  position: absolute;
  content: '';
  border-bottom: 4px dashed #ccc;
  width: 100%;
  top: 30px;
  left: 50%;
  z-index: 2;
}

.c-stepper-item .c-step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  /* border-radius: 50%; */
  /* background: #ccc; */
  margin-bottom: 6px;
}

.c-stepper-item.c-active {
  font-weight: bold;
}

/* .c-stepper-item.c-completed .c-step-counter {
  background-color: #4bb543;
} */

.c-stepper-item.c-completed.line-progress::after {
  position: absolute;
  content: '';
  border-bottom: 4px solid #4e8cdb;
  width: 100%;
  top: 30px;
  left: 50%;
  z-index: 3;
}

.c-stepper-item:first-child::before {
  content: none;
}
.c-stepper-item:last-child::after {
  content: none;
}
