/* global constant define */
* {
  --color-main: #0052cc;
  --color-dark: #172b4d;
  --color-lite: #ffffff;
  --color-error: #de350b;
  --color-border-textfield: #dfe1e6;
  --color-text-grey: #7a869a;
  --color-dark-grey: #344563;
  --color-dark-2: #172b4d;
  --color-nav-inactive: #999a9b;
  --color-black-text: #212b36;
  --color-lite-text: #637381;
  --color-table-heading: #333840;
  --color-grey-shade: #a2a2a2;
  --color-admin: #48ab4c;
  --color-operator: #0084ff;
  --color-customer: #f6c343;
}

/* global fonts classes */

.md-black {
  font-family: 'Madani-Black';
  font-weight: 400;
}
.md-thin {
  font-family: 'Madani-Thin';
  font-weight: 400;
}
.md-bold {
  font-family: 'Madani-Bold';
  font-weight: 400;
}
.md-light {
  font-family: 'Madani-Light';
  font-weight: 400;
}
.md-medium {
  font-family: 'Madani-Medium';
  font-weight: 400;
}
.md-regular {
  font-family: 'Madani-Regular';
  font-weight: 400;
}
.md-oblique {
  font-family: 'Madani-Oblique';
  font-weight: 400;
}
.md-semibold {
  font-family: 'Madani-Semibold';
  font-weight: 400;
}

/* end of global fonts classes */

/* start: declare font sizes */

.fs36-51 {
  font-size: 36px;
  line-height: 51px;
}

.fs22-35 {
  font-size: 22px;
  line-height: 35px;
}

.fs20-28 {
  font-size: 20px;
  line-height: 28px;
}

.fs14-20 {
  font-size: 14px;
  line-height: 20px;
}

.fs14-14 {
  font-size: 14px;
  line-height: 14px;
}

.fs16-23 {
  font-size: 16px;
  line-height: 23px;
}
.fs12-12 {
  font-size: 12px;
  line-height: 12px;
}
.fs16-16 {
  font-size: 16px;
  line-height: 16px;
}

.fs14-22 {
  font-size: 14px;
  line-height: 22px;
}

.fs15-21 {
  font-size: 15px;
  line-height: 21px;
}

.fs24-24 {
  font-size: 24px;
  line-height: 24px;
}

.fs32-32 {
  font-size: 32px;
  line-height: 32px;
}

.fs36-36 {
  font-size: 36px;
  line-height: 36px;
}

.fs20-22 {
  font-size: 20px;
  line-height: 22px;
}

.fs12-22 {
  font-size: 12px;
  line-height: 22px;
}

.fs18-28 {
  font-size: 18px;
  line-height: 28px;
}

.fs12-17 {
  font-size: 12px;
  line-height: 22px;
}
/* end: global font sizes classes */

/* start: some common color classes */
.color-main {
  color: var(--color-main);
}
.color-light {
  color: var(--color-lite);
}
.color-dark {
  color: var(--color-dark);
}
.color-error {
  color: var(--color-error);
}
.color-text-grey {
  color: var(--color-text-grey);
}
.color-dark-grey {
  color: var(--color-dark-grey);
}

.color-dark-2,
.color_active {
  color: var(--color-dark-2);
}
.color_inactive {
  color: var(--color-nav-inactive);
}

.color_black_text {
  color: var(--color-black-text);
}

.color_lite_text {
  color: var(--color-lite-text);
}

.color_table_heading {
  color: var(--color-table-heading);
}

.grey_shade {
  color: var(--color-grey-shade);
}

.color_admin {
  color: var(--color-admin);
}
.color_operator {
  color: var(--color-operator);
}
.color_customer {
  color: var(--color-customer);
}

.hover_ul:hover {
  text-decoration: underline;
}
/* start: some common color classes */

/* start: some other common classes */

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-pointer {
  cursor: pointer;
}

.c-not {
  cursor: not-allowed;
}

/* end: some other common classes */

/* microsoft edge password eye icon issue */

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}

/* custom cursor */

.custom_cursor {
  cursor: url('../assets/images/customCursor.png'), auto !important;
}

/* custom capitalize */

.capitalize {
  text-transform: capitalize !important;
}
