.footer_paging {
  /* position: absolute; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  height: 62px;
  width: 100%;
  background: #ffffff;
  border-width: 1px 1px 0px 0px;
  border-style: solid;
  border-color: #e4e4e4;
  box-shadow: 0px -1px 4px rgba(172, 172, 172, 0.16);
  border-radius: 0px;
}

.footer_paging button {
  font-family: 'Madani-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #333840;
}
