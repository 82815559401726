.filter-select-field {
  margin-top: 16px;
}
.filter-select-field .MuiFormControl-root {
  margin: 0 !important;
  width: 100% !important;
}

.filter-select-field .MuiInputBase-root,
.filter-select-field input,
.filter-select-field input::placeholder {
  font-family: 'Madani-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333840;
}

.filter-select-field .MuiFormLabel-root {
  font-family: 'Madani-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #909090;
}

.filter-select-field .MuiSelect-select:focus-visible {
  outline: none !important;
}
.filter-select-field .MuiSelect-nativeInput:focus-visible {
  outline: none !important;
}
.filter-select-field .MuiOutlinedInput-notchedOutline {
  outline: none !important;
  border: 1px solid #dfe1e6;
}
.filter-select-field .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid #dfe1e6;
  outline: none !important;
}

.filter-select-field input::placeholder {
  color: #909090;
  opacity: 1;
}
