.order_listing_container {
  display: flex;
  height: calc(100vh - 172px);
  /* overflow-y: auto; */
  overflow: hidden;
}

.orders_table_container {
  width: calc(100% - 280px);
  height: 100%;
  position: relative;
}
