.custom_btn:hover {
  opacity: 0.9;
}

.custom_btn:active {
  opacity: 0.8;
}
.custom_btn_disabled {
  cursor: not-allowed !important;
  background-color: #DDDDDD !important;
}
.new_loading {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--color-lite);
  color: var(--color-lite);
  animation: new_loading 1s infinite linear;
}
.new_loading::before,
.new_loading::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.new_loading::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--color-lite);
  color: var(--color-lite);
  animation: new_loading-before 1s infinite linear;
}
.new_loading::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--color-lite);
  color: var(--color-lite);
  animation: new_loading-after 1s infinite linear;
}

@keyframes new_loading-before {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes new_loading {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes new_loading-after {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
