.preview-jobpopup .MuiDialog-paper {
  border-radius: 8px !important;
  width: 564px !important;
  height: 646px !important;
  max-width: 1000px !important;
  max-height: 700px !important;
}
.preview-jobpopup .MuiDialogTitle-root {
  padding: 22px 23px 16px 30px !important;
  border: 1px solid #e4e4e4 !important;
  line-height: 25px !important;
  display: flex;
  justify-content: space-between;
}
.preview-jobpopup .dialog-title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #212b36;
}
.preview-jobpopup .MuiDialogContent-root {
  padding: 0 !important;
  background: #e6e6e6a6 !important;
}
.preview-content .heading {
  background: #f4f6f8;
  padding: 14px 30px;
}
.preview-content .jobId {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #212b36;
  padding-left: 5px;
}
.preview-content .jobIdHead {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-transform: uppercase;
  color: #898989;
}
.preview-content .jobName {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #212b36;
}
.preview-content .slide-flex-img {
  display: flex;
  padding: 20px;
  justify-content: center;
}
.preview-content .slider-img {
  /* width: 485px; */
  width: 242.5px;
  height: 517px;
  border-radius: 14px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: auto;
}
.preview-content .slider-img-inline {
  width: 230px;
  /* height: 453px; */

  border-radius: 14px;
  /* border: 1px solid black; */
}
.noImgBox {
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: white;
  margin-right: 2px;
}
.noImgeText {
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  text-transform: uppercase;
  color: #89898954;
  font-weight: 500;
}
.viewPdf-main {
  padding: 0 !important;
  background-color: white !important;
  box-shadow: none !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 14px !important;
}
.viewJob-pdf {
  height: 100% !important;
  border-radius: 14px !important;
}
.viewJob-pdf .react-pdf__Page__svg {
  height: 100% !important;
  width: 100% !important;
  border-radius: 14px !important;
}
.viewJob-pdf .react-pdf__Page__svg svg {
  height: 100% !important;
  width: 100% !important;
  border-radius: 14px !important;
}
.skeletonBox {
  width: 80%;
  margin: auto;
  padding-top: 190px !important;
}
