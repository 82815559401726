.error_header{
  padding-top: 48px;
  text-align: center;
}

.error_img {
  padding-top: 44px;
  text-align: center;
}

.footer_button{
  width: fit-content;
    border-radius: 6px;
    background: #0052CC;
    color:#ffffff;
    padding: 13px 23px 15px 23px;
}

.error_content{
  padding-top: 44px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.error_content_common{
  text-align: center;
  /* width: fit-content; */
}
.error_content_oops {
  color: var(--primary-02, #172B4D);
    text-align: center;
    /* font-family: Concert One; */
    font-size: 48px;
    line-height: normal;
}

.error_content_text1{
  padding-top: 24px;
  color: var(--primary-02, #172B4D);
    text-align: center;
    font-family: Madani-Light;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.error_content_text2 {
  padding-top: 24px;
  color: #172B4D;
    text-align: center;
    font-family: Madani-Regular;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 36px; */
}

.error_content_text3 {
  padding-top: 12px;
  color: #172B4D;
    text-align: center;
    font-family: Madani-Regular;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 36px; */
}

.error_ref_num{
  color: var(--primary-01, #0052CC);
    font-family: Madani-Medium;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
}


.error_content_text2 a{
  color: #0052CC;
  cursor: pointer;
  text-decoration: none;
}

.error_footer{
  padding-top: 44px;
  display: flex;
  justify-content: center;
}

.footer_button:hover {
  opacity: 0.8;
  cursor: pointer;
}