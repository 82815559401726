.popup_container {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  position: relative;
}
.text_container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.button_container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 36px;
}

.button_light_hover:hover {
  background-color: #ecececc7 !important;
}
