.reset_container {
  margin-top: 30px;
}
.forgot_subheading {
  margin-top: 9px;
}
.back_btn_login {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  cursor: pointer;
}
.back_btn_login_text {
  margin-left: 4px;
  width: fit-content;
  height: 24px;
  text-decoration: none;
}
.back_btn_login:hover {
  text-decoration: underline;
}
.back_btn_login:hover svg {
  transition: all 0.5s;
  transform: scale(1.2);
}
