.navbar_container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: var(--color-lite);
  height: 72px;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}

.navbar_logo img {
  width: 128px;
  height: 24px;
}

.navbar_user_container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user_avatar_container {
  background: #f2f6fc;
  border-radius: 50%;
  padding: 4.5px;
  width: max-content;
}

.navbar_user_container:hover .user_avatar_container {
  background: #d0def1;
  transition: all 0.3s;
}

.navbar_user_container:hover svg {
  transform: scale(1.4);
  transition: all 0.3s;
}
.navbar_menu_toggle {
  position: relative;
}

.user_options_container {
  margin-left: 4px;
}

/* .menu_options {
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
} */
