.login_wrapper {
  min-height: 100vh;
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  height: 100%;
  overflow-y: hidden;
}
.bg_container_left,
.bg_container_right {
  position: relative;
  height: 100vh;
}
.bg_container_right {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  overflow-y: auto;
}
.login_background_img {
  width: 100%;
  height: 100vh;
}

.heading_container {
  position: absolute;
  top: 0;
  padding: 80px 60px;
  color: #ffffff;
}
.login_heading {
  margin-bottom: 10px;
}

.form_container {
  width: 444px;
  margin-top: 100px;
}
.login_child_container {
  margin-top: 50px;
  padding-bottom: 50px;
}
