.filer_orders {
  width: 280px;
  height: 100%;
  background: #ffffff;
  border-right: 1px solid #eaeaea;
  position: relative;
  overflow-y: auto;
}

.filer_header {
  position: sticky;
  top: 0;
  z-index: 2;
  height: 48px;
  background: #f4f6f8;
  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: #eaeaea;
  border-radius: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
}

.filer_content {
  padding: 32px 24px;
  overflow-y: auto;
}
