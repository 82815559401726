.label-popup .MuiDialog-paper {
  border-radius: 8px !important;
  width: 564px !important;
  height: auto !important;
  max-width: 564px !important;
  max-height: 700px !important;
}
.label-popup .MuiDialogTitle-root {
  padding: 22px 23px 16px 30px !important;
  border: 1px solid #e4e4e4 !important;
  line-height: 25px !important;
  display: flex;
  justify-content: space-between;
}

.label-popup > .MuiDialogContent-root {
  background: #e6e6e6a6 !important;
}
.MuiDialogContent-root {
  overflow-x: hidden !important;
}

.label-popup .dialog-title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #212b36;
}
.label-popup .slide-flex-img {
  display: flex;
  padding-top: 20px;
  justify-content: center;
}
.label-popup .slider-img {
  /* width: 485px; */
  width: 242.5px;
  height: 517px;
  border-radius: 14px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: auto;
}
.label-popup .slider-img-inline {
  width: 253px;
  border-radius: 14px;
  /* border: 1px solid black; */
}
.preview-popup .MuiDialog-paper {
  border-radius: 8px !important;
  width: 1000px !important;
  height: 912px !important;
  max-width: 1256px !important;
}
.preview-popup .MuiDialogTitle-root {
  padding: 22px 23px 16px 30px !important;
  border: 1px solid #e4e4e4 !important;
  line-height: 25px !important;
  display: flex;
  justify-content: space-between;
}
.preview-popup .dialog-title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #212b36;
}
.preview-popup .pdf-preview {
  position: relative;
  height: 438px;
}
.preview-popup .dialog-button-inactive {
  color: #172b4d;
  font-weight: 500;
  font-size: 15px;
  border-radius: 6px;
  text-transform: capitalize;
  border: 1px solid #172b4d;
  min-width: 143px;
  height: 43px;
  padding: 18px !important;
  font-family: 'Madani-Medium';
}
.preview-popup .dialog-button {
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  background: #0052cc;
  border-radius: 6px;
  text-transform: capitalize;
  width: 143px;
  height: 43px;
  padding: 18px !important;
  font-family: 'Madani-Medium';
}
.preview-popup .dialog-button.MuiButton-root:hover {
  background-color: #0052cc !important;
}
.preview-action {
  justify-content: space-between !important;
  padding: 25px 36px 25px 36px !important;
}
.loading {
  /* Absolute position */
  left: 0;
  position: absolute;
  top: 0;

  /* Take full size */
  height: 100%;
  width: 100%;

  /* Center */
  align-items: center;
  display: flex;
  justify-content: center;
}

.react-pdf__Page__canvas {
  margin: auto !important;
}

.react-pdf__Document {
  background-color: #f5f5f5 !important;
  padding: 5px !important;
  box-shadow: inset 2px 2px 12px rgba(77, 77, 77, 0.17) !important;
}

.progressError-popup .dialog-button-inactive {
  color: #333840;
  font-weight: 400;
  font-size: 16px;
  border-radius: 6px;
  text-transform: capitalize;
  border: 1px solid #333840;
  min-width: 143px;
  height: 48px;
  padding: 18px !important;
  margin-top: 30px;
}

.progressError-popupAlone .dialog-button-inactive {
  color: #333840;
  font-weight: 400;
  font-size: 16px;
  border-radius: 6px;
  text-transform: capitalize;
  border: 1px solid #333840;
  min-width: 143px;
  height: 48px;
  padding: 18px !important;
  margin-top: 30px;
}
.label-popup .heading {
  background: #f4f6f8;
  padding: 14px 30px;
}
.label-popup .jobName {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #212b36;
  margin-top: 15px;
  text-align: center;
}
.noImgBox {
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: white;
  margin-right: 2px;
}
.noImgeText {
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  text-transform: uppercase;
  color: #89898954;
  font-weight: 500;
}
.slickNext {
  position: absolute;
  right: 0;
}
.slider-div .controls {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 39%;
  width: 90%;
}
.sliderDiv {
  width: 90%;
  margin: auto;
}
.slickBut {
  background: none !important;
  border: none !important;
  cursor: pointer;
  width: fit-content !important;
}
.disabled {
  cursor: not-allowed;
}

/* .react-pdf__message--no-data .skeletonBoxArt {
  width: 80%;
  margin: auto;
  padding-top: 140px !important;
  height: 100%;
} */

.skeletonBoxArt {
  width: 80%;
  margin: auto;
  padding-top: 140px !important;
  /* height: 250px; */
  /* height: 100%; */
  height: 500px;
}
