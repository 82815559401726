.order_popup .MuiDialog-paper {
  border-radius: 8px !important;
  width: 800px !important;
  max-width: 800px !important;
}
.order_popup .MuiDialogTitle-root {
  padding: 22px 23px 16px 30px !important;
  border: 1px solid #e4e4e4 !important;
  line-height: 25px !important;
  display: flex;
  justify-content: space-between;
}
.order_popup .dialog-title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #212b36;
}
.order_popup .dialog-button {
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  background: #dcdcdc;
  border-radius: 6px;
  text-transform: capitalize;
  width: 143px;
  height: 48px;
  padding: 18px !important;
}
.order_popup .dialog-button-disabled {
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  background: #0052cc;
  border-radius: 6px;
  text-transform: capitalize;
  width: 143px;
  height: 48px;
  padding: 18px !important;
}
.order_popup .dialog-button-inactive {
  color: #333840;
  font-weight: 400;
  font-size: 16px;
  border-radius: 6px;
  text-transform: capitalize;
  border: 1px solid #333840;
  width: 143px;
  height: 48px;
  padding: 18px !important;
}
.order_popup .dialog-button.MuiButton-root:hover {
  background-color: #0052cc !important;
}
.order_popup .dialog-button-disabled.MuiButton-root:hover {
  background-color: #0052cc !important;
}
.order_popup .MuiDialogActions-root {
  padding: 20px 42px 33px 42px !important;
}
.order_popup .order-step-1 {
  padding: 10px 42px 0px 41px !important;
}
.order_popup .MuiDialogContent-root {
  padding: 0 !important;
}
.order-heading {
  color: #333840;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
}
.displayNone {
  display: none;
}
.order_popup .uploadImage {
  width: 100%;
  height: 84px;
  background: #f8f9fb;
  border: 2px dashed #d8dde3;
  border-radius: 5px;
  color: #0052cc;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border-image-source: url('../../assets/images/rectangle.png');
  border-image-slice: 2;
  border-image-repeat: round;
  /* or use the shorthand border-image */
  border-image: url('../../assets/images/rectangle.png') 2 round;
  cursor: pointer;
}

.largeGaps {
  border-image-source: url('../../assets/images/rectangle.png');
}

.uploadImageActive {
  width: inherit;
  background: #d6f4e7;
  border: 1px solid #00875a;
  border-radius: 5px;
  display: block;
  font-weight: 400;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  padding: 21px 16.2px;
}
.fileName {
  font-weight: 400;
  font-size: 16px;
  color: #333840;
}
.fileSize {
  font-weight: 400;
  font-size: 13px;
  color: #6d6f72;
}
.uploadImageInActive {
  width: inherit;
  background: rgba(222, 53, 11, 0.04);
  border: 1px solid #de350b;
  border-radius: 5px;
  display: block;
  font-weight: 400;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  padding: 21px 16.2px;
}
.order_popup .errorMsg {
  color: #de350b;
  font-weight: 400;
  font-size: 12px;
  /* margin-top: px; */
}
.order_popup .position-absolute {
  position: relative;
  top: 4px;
}
.slider-div {
  margin-bottom: 0;
  margin-top: 10px;
  width: 100%;
  display: flex;
}
.slider-div .slider-img {
  width: 70px;
  height: 119.74px;
  border-radius: 6px;
}
.slider-div .slider-box-active {
  width: 180px;
  height: auto;
  min-height: 200px;
  background: #e6e6e6a6;
  border: 1px solid #172b4d;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px 6.3px;
  cursor: pointer;
  padding: 10px 0;
  margin: auto;
}
.slider-div .slider-box-inactive {
  width: 180px;
  height: auto;
  min-height: 200px;
  background: #e6e6e6a6;
  border-radius: 6px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px 6.3px;
  cursor: pointer;
  padding: 10px 0;
  margin: auto;
}
.slider-div .slide-flex-img {
  display: flex;
  justify-content: center;
}
.slider-div .slider-img-inline {
  width: 80px;
  background: #e6e6e6a6;
  border-radius: 6px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px;
  cursor: pointer;
  /* border: 1px solid black; */
}
.display-block {
  display: block;
}
.slider-div .slider-name {
  font-weight: 500;
  font-size: 12px;
  color: #333840;
  margin-top: 5px;
  font-family: 'Madani-Regular';
}
.slider-div .preview-button {
  font-size: 12px;
  text-align: center;
  color: #0052cc;
  margin: auto;
  margin-top: 8px;
  cursor: pointer;
  width: fit-content;
  font-family: 'Madani-Medium';
}
.progress-popup {
  padding: 20px 24px !important;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.progress-popup .progress-text {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #333840;
  padding-top: 30px;
}
.progress-popup .MuiBox-root {
  margin: 0 !important;
}
.progress-popup .MuiLinearProgress-root {
  background-color: #dbefe5 !important;
  border-radius: 12px !important;
  height: 7px !important;
}
.progress-popup .MuiLinearProgress-bar {
  background-color: #36b37e !important;
  border-radius: 12px !important;
}

.progressError-popup {
  padding: 0px 24px !important;
  width: 370px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.progressError-popup .progress-text {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #333840;
  padding-top: 18px;
}
.progressError-popup .MuiBox-root {
  margin: 0 !important;
}
.progressError-popup .MuiLinearProgress-root {
  background-color: #dbefe5 !important;
  border-radius: 12px !important;
  height: 7px !important;
}
.progressError-popup .MuiLinearProgress-bar {
  background-color: #36b37e !important;
  border-radius: 12px !important;
}
.progressError-popup .dialog-button {
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  background: #0052cc;
  border-radius: 6px;
  text-transform: capitalize;
  width: 143px;
  height: 48px;
  padding: 18px !important;
  margin-top: 30px;
}
.progressError-popup .dialog-button.MuiButton-root:hover {
  background-color: #0052cc !important;
}
.MuiDialog-container {
  backdrop-filter: blur(5px) !important;
}

.progressError-popupAlone {
  /* width: 370px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.progressError-popupAlone .progress-text {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #333840;
  padding-top: 18px;
}
.progressError-popupAlone .MuiBox-root {
  margin: 0 !important;
}
.progressError-popupAlone .MuiLinearProgress-root {
  background-color: #dbefe5 !important;
  border-radius: 12px !important;
  height: 7px !important;
}
.progressError-popupAlone .MuiLinearProgress-bar {
  background-color: #36b37e !important;
  border-radius: 12px !important;
}
.progressError-popupAlone .dialog-button {
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  background: #0052cc;
  border-radius: 6px;
  text-transform: capitalize;
  width: 143px;
  height: 48px;
  padding: 18px !important;
  margin-top: 30px;
}
.progressError-popupAlone .dialog-button.MuiButton-root:hover {
  background-color: #0052cc !important;
}
