.sidebar {
  position: fixed;
  top: 73px;
  left: 0;
  bottom: 0;
  background-color: var(--color-lite);
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  will-change: width;
  transition: width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}
.sidebar_expand,
.sidebar_collapse {
  z-index: 5;
}
/* 

.sidebar_collapse:hover {
  width: 146px;
} */

.sidebar_navigation {
  box-sizing: border-box;
  flex-direction: column;
  height: 100%;
  overflow: hidden auto;
  width: 100%;
  will-change: width;
  transition: width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}

.sidebar_navigation_collapse {
  display: flex;
  width: 50px;
}
.sidebar_navigation_collapse .sidebar_navigation_wrapper {
  visibility: hidden;
}

.sidebar_navigation_collapse:hover .sidebar_navigation_wrapper {
  visibility: visible;
}

.sidebar_navigation_collapse:hover,
.sidebar_navigation_expand {
  display: flex;
  width: 146px;
}

.horizontal_container {
  bottom: 0px;
  left: 100%;
  position: absolute;
  top: 0px;
  transform: translateZ(0px);
  width: 24px;
  z-index: 1;
}

.horizontal_line {
  background: var(--color-border-textfield);
  bottom: 0px;
  left: -1px;
  opacity: 0.5;
  pointer-events: none;
  position: absolute;
  top: 0px;
  transition-duration: 0.22s;
  transition-property: left, opacity, width;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  width: 1px;
}

.horizontal_line_bold {
  background: var(--color-main);
  opacity: 0;
  height: 100%;
  left: 3px;
  position: absolute;
  transition: opacity 200ms ease 0s;
  width: 3px;
}

.horizontal_area {
  cursor: ew-resize;
  height: 100%;
  left: -4px;
  position: relative;
  width: 24px;
}

.horizontal_area:hover .horizontal_line_bold {
  opacity: 1;
}

.button_position {
  position: absolute;
  top: 35px;
  left: -16px;
  z-index: 2;
  cursor: pointer;
  width: 100%;
  height: fit-content;
}

.horizontal_area:hover + .button_position svg rect,
.button_position:hover svg rect {
  fill: #dfe1e6;
}

.horizontal_container:hover ~ .sidebar_navigation_collapse {
  display: flex;
  width: 146px;
  visibility: visible;
}

.sidebar_navigation_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 98px;
}
