.Toastify .Toastify__close-button {
  display: block;
}
.Toastify__close-button > svg{
  fill: white;
}
.Toastify .Toastify__toast-container {
  text-align: center;
}
.Toastify__toast-icon > svg{
  fill: white;
}
.Toastify .Toastify__toast--info {
  background: #000000;
  border-radius: 4px;
  font-weight: bold;
  color: white;
  font-size: 15px;
}
.Toastify .Toastify__toast--success {
  background: #0052cc;
  border-radius: 4px;
  font-weight: bold;
  color: white;
  font-size: 15px;
}
.Toastify .Toastify__toast--error {
  background: #ED3B41;
  border-radius: 4px;
  font-weight: bold;
  color: white;
  font-size: 15px;
}
.Toastify .Toastify__toast--success div{
  word-break: break-word;
}
.Toastify .Toastify__toast--error div{
  word-break: break-word;
}