.order_details_header,
.order_number,
.order_name {
  color: #212b36;
}

.order_details_header {
  padding: 22px 30px 16px 30px;
  position: relative;
}

.details_close_btn {
  position: absolute;
  right: 23px;
  top: 23px;
}

.order_details_container {
  background: #f4f6f8;
  padding: 12px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order_id_container {
  text-transform: uppercase;
  color: #898989;
}

.order_date_container {
  color: #898989;
  text-transform: capitalize;
}

.order_date_data {
  color: #212b36;
  text-align: end;
}

.order_name {
  padding-top: 5px;
}

.order_details_status {
  padding: 16px 36px 22px 30px;
  border-bottom: 1px solid #eaeaea;
  min-height: 121px;
}

.order_details_comments {
  padding: 20px 36px 22px 36px;
}

.order_details_btn {
  padding: 20px 36px 22px 36px;
  display: flex;
  justify-content: flex-end;
}

.button_hover_cancel:hover {
  background-color: #ecececc7 !important;
}

.comments_remarks {
  margin-top: 9px;
  padding: 9px 9px 9px 16px;
  background: #f8fafd;
  border-radius: 8px;
}
.comments_remarks_data {
  max-height: 180px;
  overflow-y: scroll;
}

.remarks_details {
  max-width: 95%;
}
.remarks_profile {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.user_message_name {
  font-style: italic;
  text-transform: capitalize;
}
.user_timestamp {
  margin-left: 6px;
  color: #626262;
  font-size: 12px;
  line-height: 17px;
}

.user_message_data {
  font-size: 12px;
  line-height: 17px;
  color: #595959;
}

.cust_btn_order {
  cursor: not-allowed !important;
}
