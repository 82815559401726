.login_welcome_header {
  margin-top: 20px;
  margin-bottom: 10px;
}
.login_welcome_sub_header {
  margin-bottom: 36px;
}

.forgot-button {
  margin-top: 14px;
  cursor: pointer;
  text-align: end;
  color: var(--color-main);
  margin-bottom: 18px;
  text-decoration: none;
}
.forgot-button:hover {
  text-decoration: underline;
}

.pass_visible {
  color: var(--color-text-grey);
}

.pass_visible:hover {
  color: var(--color-main);
}
