.dash_container {
  padding: 24px 36px;
}
.graph_container {
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
  padding: 24px 0px 18px 24px;
  margin-top: 32px;
}
.graph_heading {
  color: #212b36;
}
.graph_subheading {
  color: #4c9aff;
  padding-top: 4px;
}
.graph_tabs {
  padding-top: 48px;
}

.graph_mainDiv {
  background: #ffffff;
}

.graph_outerDiv {
  margin-top: 40px;
}

.graph_header_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.graph_filter_div {
  margin-right: 28px;
  width: fit-content;
  background: #f6f7f8;
  border-radius: 6px;
  padding: 0px 12px;
}

.graph_month_select {
}

.graph_month_select .MuiFormControl-root {
  margin: 0 !important;
  width: 100% !important;
}
.graph_month_select .MuiSelect-select:focus-visible {
  outline: none !important;
}
.graph_month_select .MuiSelect-nativeInput:focus-visible {
  outline: none !important;
}
.graph_month_select .MuiInputBase-root:hover {
  border: none !important;
  outline: none !important;
}
.graph_month_select .MuiOutlinedInput-notchedOutline {
  outline: none !important;
  border: none !important;
  background: rgba(145, 158, 171, 0.08);
  border-radius: 6px;
}

.graph_year_div {
  padding: 8.5px 14px;
  background: rgba(145, 158, 171, 0.08);
  border-radius: 6px;
}

.graph_filter_line {
  width: 2px;
  background: #c4c4c4;
  height: 30px;
}

.graph_print_heading {
  background: #f8f8f8;
  border-radius: 4px;
  padding: 7px 17px 7px 17px;
  cursor: pointer;
}

.graph_print_heading:hover {
  background: #f0f6ff;
}
.statistic_active {
  background: #f0f6ff;
  color: #0052cc;
}
