.basic-textfield {
  margin-top: 16px;
}
.basic-textfield .MuiFormControl-root {
  margin: 0 !important;
  width: 100% !important;
}

.basic-textfield .MuiOutlinedInput-notchedOutline {
  outline: none !important;
  border: 1px solid #dfe1e6;
}
.basic-textfield .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #0052cc;
  outline: none !important;
}

.basic-textfield .MuiInputBase-root,
.basic-textfield input,
.basic-textfield input::placeholder {
  font-family: 'Madani-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333840;
}

.basic-textfield .MuiFormLabel-root {
  font-family: 'Madani-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #909090;
}

.basic-textfield input::placeholder {
  color: #909090;
  opacity: 1;
}
