.headingComment{
	padding: 10px;
	display: flex;
	justify-content: space-between;
	padding-bottom: 0;
}
.contentComment{
	padding: 5px 10px 12px 10px;
}
.contentComment .MuiFilledInput-root{
	padding: 7px 8px !important;
	background: #F8FAFD !important;
	border-radius: 3px !important;
}
.contentComment .MuiFilledInput-root:before{
	border: none !important;
}
.contentComment .MuiTextField-root{
	width: 100% !important;
}
.contentComment .MuiFilledInput-root:after{
	border: none !important;
}
.contentComment .MuiFilledInput-input{
	font-weight: 400 !important;
	font-size: 12px !important;
}
.contentComment .postComment{
	font-size: 14px;
	line-height: 16px;
	color: #0052CC;
	width: fit-content;
	margin-top: 15px;
	margin-right: 16px;
	cursor: pointer;
	font-family: 'Madani-Medium';
}
.contentComment .postMain{
	display: flex;
	justify-content: flex-end;
}
.preview-shipment .MuiDialog-paper {
  border-radius: 8px !important;
  width: 619px !important;
	height: 450px !important;
  max-width: 700px !important;
	max-height: 500px !important;
}
.preview-shipment .MuiDialogTitle-root {
  padding: 22px 23px 16px 30px !important;
  border: 1px solid #e4e4e4 !important;
  line-height: 25px !important;
  display: flex;
  justify-content: space-between;
}
.preview-shipment .dialog-title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #212b36;
}
.preview-shipment .MuiDialogContent-root{
	padding: 0 !important;
}

.shipment-content .MuiFormControl-root{
	width: 100%;
	padding: 35px 0px;
}
.shipment-content .MuiFormGroup-root{
	width: 100%;
	justify-content: space-around;
}
.shipment-content .MuiFormControlLabel-label {
	font-weight: 400;
	font-size: 16px;
	line-height: 28px;
	color: #333840;
	font-family: 'Madani-Regular';
}
.shipmentField{
  margin: 0px 35px !important;
}
.shipmentField .MuiFormControl-root{
  padding: 0px !important;
}
.shipmentField .MuiInputBase-root{
	padding: 12px 14px !important;
}
.shipmentField .MuiInputBase-input{
	font-family: 'Madani-Regular';
	font-size: 12px;
}
.shipmentField .MuiFormHelperText-root{
	text-align: end !important;
	margin-right: 0 !important;
	color: #AAAAAA !important;
}
.preview-shipment .dialog-button-inactive {
  color: #333840;
  font-weight: 400;
  font-size: 16px;
  border-radius: 6px;
  text-transform: capitalize;
  border: 1px solid #333840;
  min-width: 143px;
  height: 48px;
  padding: 18px !important;
	margin-right: 30px !important;
}
.preview-shipment .dialog-button {
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  background: #0052cc;
  border-radius: 6px;
  text-transform: capitalize;
  width: 143px;
  height: 48px;
  padding: 18px !important;
}
.preview-shipment .dialog-button.MuiButton-root:hover {
  background-color: #0052cc !important;
}
.preview-shipment {
  padding: 20px 35px 30px 35px !important;
}
.shipment-content .inactiveRadio .MuiTypography-root{
	color: #B3B3B3 !important;
}
.shipment-content .inactiveRadio .css-hyxlzm{
	color: #B3B3B3 !important;
}