.avatar_container {
  background: #f9fcff;
  border-bottom: 1px solid #e4e4e4;
  padding: 24px 36px;
  display: flex;
  align-items: center;
}
.avatar_details {
  margin-left: 22px;
}
.avatar_name {
  color: #212b36;
}
.avatar_username {
  margin-left: 6px;
  margin-top: 4px;
  color: #333840;
}

.user_details_container {
  padding: 30px 36px;
}

.info_heading {
  text-transform: uppercase;
  color: #333840;
}
.info_subheading {
  color: #909090;
  text-transform: capitalize;
}

.info_data {
  margin-top: 14px;
  color: #344563;
  overflow-wrap: break-word;
}
