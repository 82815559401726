.menu_container {
  /* position: absolute;
  top: calc(100% + 8px);
  right: 0;
  z-index: 100; */
  width: fit-content;
}

.menu_account_w {
  background: #ffffff;
  width: 260px;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
  z-index: 100px !important;
}

.user_details_menu {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.menu_account {
  margin-bottom: 20px;
  color: #212b36;
}
.menu_name {
  color: #333840;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.menu_email {
  color: #344563;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.menu_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 12px;
  width: 210px;
}

.menu_divider {
  margin-left: -16px;
  margin-right: -16px;
  border-bottom: 1px dashed rgba(145, 158, 171, 0.24);
  margin-bottom: 17px;
}

.menu_options_data {
  color: var(--color-error);
  cursor: pointer;
}

.menu_options_data:hover {
  text-decoration: underline;
}
